import React from "react"

import Layout from "../../components/Layout"
import TimetableNotice from "../../components/Content/TimetableNotice"
import ExtendedTours from "../../components/ExtendedTours"

import SEO from "../../components/SEO"

const ExtendedToursPage = (props) => {
  return (
    <Layout location={props.location}>
      <SEO
        title="Extended Tours"
        description="Our Extended Tours vary in length and are a great opportunity to get out and see our country"
      />
      <TimetableNotice />
      <ExtendedTours perPage={10} />
    </Layout>
  )
}

export default ExtendedToursPage
